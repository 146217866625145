import { Injectable, inject } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, pipe, throwError, OperatorFunction } from 'rxjs';
import { tap, switchMap, map, withLatestFrom, catchError as catchErrorRxjs, filter } from 'rxjs/operators';
import { SellerDetailService } from 'apps/middle/src/app/sellers-module/services';
import { TransferDetailService } from 'apps/middle/src/app/transfers-module/services';
import { HttpErrorMessage, ActionType, IDocumentFile } from '@aston/foundation';
import { asInvoiceField } from 'apps/middle/src/app/transfers-module/functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DebtorsService } from 'apps/middle/src/app/debtors-module/services';
import { TransferState } from 'apps/middle/src/app/transfers-module/enums';
import { mapSurveyToEmailPreviewModel } from 'apps/middle/src/app/shared-module/functions/mappers.function';
import { AppConstants } from 'apps/middle/src/app/app.constants';

import { FactorFeaturesStoreSelectors } from '../factor-features-store';
import { catchWithAppError, AppStoreActions } from '../app-store';
import { CorrelationParams, aggregate } from '../models/correlated-actions.model';
import { TransfersListStore } from '../../transfers-module/store';
import { MailWriterStore } from '../../shared-module/stores';
import { EmailViewerModalComponent } from '../../shared-module/components/email-viewer-modal/email-viewer-modal.component';

import * as featureSelectors from './selectors';
import * as featureActions from './actions';

const catchError = function catchKycStalledError<T, A extends Action, A1 extends Action>(selector: (err: HttpErrorMessage) => Observable<T>): OperatorFunction<A, Action|T|A|A1> {
	return pipe(
		catchErrorRxjs((error: HttpErrorMessage): Observable<Action> => {
			if (error.is('InvalidDocumentState') || error.is('InvalidDocumentFileState')) {
				return of(
					featureActions.ValidateInvoiceDocumentFailure(),
					featureActions.Notify({ message: { level: 'warning', messageKey: error.key } })
				);
			} else if (error.is('NoInsuranceCoverage') || error.is('InvalidSurveyState')) {
				return of(
					featureActions.UpdateTransferGlobalStatusFailure({ error }),
					featureActions.Notify({ message: { level: 'warning', messageKey: error.key } })
				);
			} else {
				return throwError(error);
			}
		}),
		catchWithAppError(selector)
	);
};

@Injectable({
	providedIn: 'root'
})
export class TransferControlStoreEffects {
	transfersListStore = inject(TransfersListStore);

	constructor(
		private actions$: Actions,
		private store: Store,
		private sellerDetailService: SellerDetailService,
		private debtorsService: DebtorsService,
		private transferDetailService: TransferDetailService,
		private mailWriter: MailWriterStore,
		protected modalService: NgbModal,
	) {
	}

	onDebug$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Debug),
		tap(action => console.log(`Debug action %o triggered by`, action.message, action.origin))
	), { dispatch: false });

	loadRequestBySellerIdEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadTransferRequest),
		switchMap(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return this.transferDetailService.getTransfer(action.id).pipe(
				switchMap(transfer => [
					featureActions.LoadTransferSuccess({ entity: transfer, correlationParams }),
					//featureActions.LoadTransferEntitiesSuccess({ entity: transfer.invoices, correlationParams }), // because transfer already contains invoices
					featureActions.LoadSurveysRequest(transfer.id, correlationParams),
					featureActions.LoadSellerIdentityRequest(action.sellerId, correlationParams),
					featureActions.LoadPanelInformationRequest(transfer.id, correlationParams),
					featureActions.LoadDebtorContactsRequest(transfer.debtorId, correlationParams),
					featureActions.LoadInsuranceDecisionRequest(transfer.id, correlationParams)
				]),
				catchError(error => of(featureActions.LoadTransferFailure({ error })))
			);
		})
	));

	getAggregatedLoadBySellerId$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadTransferRequest),
		// Create an aggregated action listener
		// it will observe the race of all specified observable
		// and complete when all these actions are dispatched.
		// Note that individual actions workflow still apply.
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadTransferSuccess)),
			this.actions$.pipe(ofType(featureActions.LoadSurveysSuccess)),
			this.actions$.pipe(ofType(featureActions.LoadTransferEntitiesSuccess)),
			this.actions$.pipe(ofType(featureActions.LoadSellerIdentitySuccess)),
			this.actions$.pipe(ofType(featureActions.LoadPanelInformationSuccess)),
			this.actions$.pipe(ofType(featureActions.LoadTransferFailure))
		),
		map(_ => featureActions.TransferFullyLoaded()),
		catchError(error => of(featureActions.LoadTransferFailure({ error })))
	));

	loadSellerIdentityEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSellerIdentityRequest),
		switchMap(action =>
			this.sellerDetailService.getSellerIdentity(action.id).pipe(
				map(entity => featureActions.LoadSellerIdentitySuccess({ entity, correlationParams: action.correlationParams })),
				catchError(error => of(featureActions.LoadSellerIdentityFailure({ error })))
			)
		)
	));

	loadDebtorContactsRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorContactsRequest),
		switchMap(action =>
			this.debtorsService.getDebtorContacts(action.id).pipe(
				map(entity => featureActions.LoadDebtorContactsSuccess({ entity, correlationParams: action.correlationParams })),
				catchError(error => of(featureActions.LoadDebtorContactsFailure({ error })))
			)
		)
	));

	loadPanelInformationEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadPanelInformationRequest),
		switchMap(action =>
			this.transferDetailService.getPanelInformation(action.id).pipe(
				map(entity => featureActions.LoadPanelInformationSuccess({ entity, correlationParams: action.correlationParams })),
				catchError(error => of(featureActions.LoadPanelInformationFailure({ error })))
			)
		)
	));

	loadInsuranceDecisionEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadInsuranceDecisionRequest),
		switchMap(action =>
			this.transferDetailService.getInsurranceDecision(action.id).pipe(
				map(entity => featureActions.LoadInsuranceDecisionSuccess({ entity, correlationParams: action.correlationParams })),
				catchError(error => of(featureActions.LoadInsuranceDecisionFailure({ error })))
			)
		)
	));

	onUpdateTransferGlobalStatusRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateTransferGlobalStatusRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectTransfer),
			this.store.select(featureSelectors.selectPanelInfos),
			this.store.select(featureSelectors.selectSellerIdentity)),
		switchMap(([action, transfer, panelInfos, sellerInfos]) =>
			this.transferDetailService.updateTransferGlobalState(transfer.id, action.state).pipe(
				switchMap(_ => {
					// forge the updated debtor infos
					const { seller, debtor: debtorInfos, pendingDebtor } = panelInfos;
					const debtor = {
						...debtorInfos,
						totalOutstandingAmount: debtorInfos.totalOutstandingAmount /* + transfer.invoiceAmount */
					};
					// forge the updated seller identity
					const sellerIdentity = {
						...sellerInfos,
						totalOutstandingAmount: sellerInfos.totalOutstandingAmount /* + transfer.invoiceAmount */
					};

					return [
						featureActions.UpdateTransferGlobalStatusSuccess({ entity: action.state, correlationParams: action.correlationParams }),
						featureActions.LoadSellerIdentitySuccess({ entity: sellerIdentity }),
						featureActions.LoadPanelInformationSuccess({ entity: { seller, debtor, pendingDebtor } }),
						AppStoreActions.Execute(() => this.transfersListStore.showSimilarTransfersModal({
							bySellerId: sellerInfos.id,
							exceptIds: [transfer.id]
						})),
					];
				}),
				catchError(error => of(featureActions.UpdateTransferGlobalStatusFailure({ error })))
			)
		)
	));

	onValidateInsuranceDecision$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ValidateInsuranceDecisionRequest),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, transfer]) =>
			this.transferDetailService.validateInsuranceDecision(transfer.id, action.state).pipe(
				map(_ => featureActions.ValidateInsuranceDecisionSuccess({ insuranceDecision: action.insuranceDecision, state: action.state })),
				catchError(_ => of(AppStoreActions.ToastError('NotificationMessages.Errors.Generic')))
			)
		)
	));

	onValidateInvoiceDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ValidateInvoiceDocumentRequest),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, transfer]) =>
			this.transferDetailService.validateInvoiceDocument(transfer.id, action.document.id, action.state)
			.pipe(
				map(_ => featureActions.ValidateInvoiceDocumentSuccess({
					entity: action.entity, document: action.document, state: action.state
				})),
				catchError(_ => of(AppStoreActions.ToastError('NotificationMessages.Errors.Generic')))
			)
		)
	));

	onRetryInsuranceDecision$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.RetryInsuranceDecisionRequest),
		switchMap(action =>
			this.transferDetailService.retryInsuranceDecision(action.transferId).pipe(
				map(entity => featureActions.LoadInsuranceDecisionSuccess({ entity })),
				catchError(error => of(featureActions.RetryInsuranceDecisionError({ error })))
			)
		)
	));

	onLoadSurveysEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSurveysRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectTransfer),
			this.store.select(featureSelectors.selectSurveys)),
		switchMap(([action, transfer, surveys]) =>
			this.transferDetailService.getTransferSurveys(transfer.id).pipe(
				map(entity => featureActions.LoadSurveysSuccess({
					entity: asInvoiceField(entity, surveys.state),
					correlationParams: action.correlationParams
				})),
				catchError(error => of(featureActions.LoadSurveysFailure({ error })))
			)
		)
	));

	onCreateSurveyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CreateSurveyRequest),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, transfer]) =>
			this.transferDetailService.createSurvey(transfer.id, action.entity).pipe(
				switchMap(_ => [
					featureActions.CreateSurveySuccess({ entity: action.entity, correlationParams: action.correlationParams }),
					featureActions.LoadSurveysRequest(transfer.id)
				]),
				catchError(error => of(featureActions.CreateSurveyFailure({ error })))
			)
		)
	));

	onLoadSurveyRequestEffect: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSurveyRequest),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, transfer]) =>
			this.transferDetailService.getSurveyDetails(transfer.id, action.id, action.surveyType).pipe(
				map(entity => featureActions.LoadSurveySuccess({ entity, correlationParams: action.correlationParams })),
				catchError(error => of(
					featureActions.LoadSurveyFailure({ error }),
					AppStoreActions.ToastError('NotificationMessages.Errors.Generic')
				))
			)
		)
	));

	// loadTransferEntitiesEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
	// 	ofType(featureActions.LoadTransferEntitiesRequest),
	// 	switchMap(action =>
	// 		this.transferDetailService.getTransfer(action.id).pipe(
	// 			map(transfer => featureActions.LoadTransferEntitiesSuccess({
	// 				entity: transfer.invoices, correlationParams: action.correlationParams
	// 			})),
	// 			catchError(error => of(featureActions.LoadTransferEntitiesFailure({ error })))
	// 		)
	// 	)
	// ));

	onUpdateSurveyEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSurveyRequest),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, transfer]) =>
			this.transferDetailService.updateSurvey(transfer.id, action.entity).pipe(
				switchMap(_ => [
					featureActions.UpdateSurveySuccess({ entity: action.entity }),
					featureActions.LoadSurveysRequest(transfer.id)
				]),
				catchError(error => of(featureActions.UpdateSurveyFailure({ error })))
			)
		)
	));

	openSuperDebtorSurveySentMailEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSuperDebtorSurveySentMail),
		withLatestFrom(
			this.store.select(featureSelectors.selectTransfer),
			this.store.select(featureSelectors.selectSuperDebtorAutomaticSurvey)),
		switchMap(([_, transfer, autoSurvey]) =>
			this.transferDetailService.getSurveySentMail(transfer.id, autoSurvey.id).pipe(
				tap((preview) => {
					const modalRef = this.modalService.open(EmailViewerModalComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
					const modalInstance: EmailViewerModalComponent = modalRef.componentInstance;
					modalInstance.title = 'Dunning.Sent.Mail.Title';
					modalInstance.email = preview;
				}))
		)), { dispatch: false });

	onPreviewSurveyEmailEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.PreviewSurveyEmail),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, transfer]) =>
			this.transferDetailService.getMailPreview(transfer.id, action.entity).pipe(
				tap((preview) => {
					const modalRef = this.modalService.open(EmailViewerModalComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
					const modalInstance: EmailViewerModalComponent = modalRef.componentInstance;
					const survey = action.entity;

					modalInstance.email = preview;

					// Define actions only for creation mode
					if (!survey.id) {
						modalInstance.showAction = true;

						modalInstance.actionResult.pipe(
							map(result => {
								modalRef.dismiss();

								if (result.type === ActionType.SUBMIT) {
									this.store.dispatch(featureActions.CreateSurveyRequest(survey));
								}
							})
						).subscribe();
					}
				}))
	)), { dispatch: false });

	onPreviewSurveySentEmailEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.PreviewSentSurveyRequest),
		tap(action => {
			const modalRef = this.modalService.open(EmailViewerModalComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
			const modalInstance: EmailViewerModalComponent = modalRef.componentInstance;

			modalInstance.email = mapSurveyToEmailPreviewModel(action.entity);
		}
	)), { dispatch: false });

	onSurveyFormSuccessEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CreateSurveySuccess, featureActions.UpdateSurveySuccess),
		map(_ =>
			featureActions.SetSurveyShowForm({ param: false })
		)
	));

	onValidateSurveysEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ValidateSurveysRequest),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, transfer]) =>
			this.transferDetailService.validateSurveys(transfer.id, action.state).pipe(
				map(_ => featureActions.ValidateSurveysSuccess({ state: action.state })),
				catchError(_ => of(AppStoreActions.ToastError('NotificationMessages.Errors.Generic')))
			)
		)
	));

	openTransferValidationDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateTransferGlobalStatusSuccess),
		filter(action => action.entity === TransferState.Valid),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		withLatestFrom(this.store.select(FactorFeaturesStoreSelectors.selectSendPostalMailTransferValidationTemplateId)),
		filter(([_, postalMailTemplateId]) => !!postalMailTemplateId),
		switchMap(([[_, transfer]]) => this.transferDetailService.getTransfer(transfer.id)
		.pipe(
			map(transferRefreshed => !transferRefreshed.postalMailSentToSuperDebtorOnFirstValidationDocumentFile ?
				AppStoreActions.Noop()
				: AppStoreActions.OpenDocument({ document: { ...transferRefreshed.postalMailSentToSuperDebtorOnFirstValidationDocumentFile } as IDocumentFile }))
		)
		)
	));

	sendSellerMail$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SendSellerMailRequest),
		withLatestFrom(this.store.select(featureSelectors.selectSellerIdentity)),
		switchMap(([action, identity]) => {
			return this.sellerDetailService.sendEmail(identity.id, action.entity).pipe(
				switchMap(_ => [
					featureActions.SendSellerMailSuccess({ entity: action.entity, correlationParams: action.correlationParams }),
					AppStoreActions.Execute(() => this.mailWriter.onMailSent())
				]),
				catchError(error => of(
					featureActions.SendSellerMailFailure({ error }),
				))
			);
		})
	));

	getSellerEmailPreview = createEffect(() => this.actions$.pipe(
		ofType(featureActions.GetSellerEmailPreview),
		withLatestFrom(this.store.select(featureSelectors.selectSellerIdentity)),
		switchMap(([action, identity]) => this.sellerDetailService.getMailPreview(identity.id, action.mailData).pipe(
			map(entity => AppStoreActions.Execute(() => this.mailWriter.onPreviewSuccess(entity))),
			catchError(error => of(AppStoreActions.ErrorLog(error))
		)))
	));

	sendDebtorMail$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SendDebtorMailRequest),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, entity]) => {
			return this.debtorsService.sendEmailToSuperDebtor(entity.superDebtorId, action.entity).pipe(
				switchMap(_ => [
					featureActions.SendDebtorMailSuccess({ entity: action.entity, correlationParams: action.correlationParams }),
					AppStoreActions.Execute(() => this.mailWriter.onMailSent())
				]),
				catchError(error => of(
					featureActions.SendDebtorMailFailure({ error }),
				))
			);
		})
	));

	getDebtorEmailPreview = createEffect(() => this.actions$.pipe(
		ofType(featureActions.GetDebtorEmailPreview),
		withLatestFrom(this.store.select(featureSelectors.selectTransfer)),
		switchMap(([action, entity]) => this.debtorsService.getMailPreview(entity.superDebtorId, action.mailData).pipe(
			map(entity => AppStoreActions.Execute(() => this.mailWriter.onPreviewSuccess(entity))),
			catchError(error => of(AppStoreActions.ErrorLog(error))
		)))
	));
}
